<template>
  <v-app>
    <app-bar />

    <v-main>
      <router-view />
    </v-main>
    <snackbar />
  </v-app>
</template>

<script>
import AppBar from '@/components/AppBar.vue'
import Snackbar from '@/components/Snackbar.vue'
export default {
  name: 'App',
  components: {
    AppBar,
    Snackbar,
  },
}
</script>
