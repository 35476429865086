import Vue from 'vue'
import Vuex from 'vuex'
import api from '@/api'
import { urls } from '@/constants/api'

Vue.use(Vuex)

export const mutations = {
  SET_LOADING: 'set_loading',
  UNSET_LOADING: 'unset_loading',

  SET_SNACKBAR: 'set_snackbar',

  SET_ONGOING_INCIDENTS: 'set_ongoing_incidents',
  SET_ONGOING_STATUS: 'set_ongoing_status',
  SET_RESOLVED_INCIDENTS: 'set_resolved_incidents',
  SET_INCIDENT: 'set_incident',
  SET_INCIDENT_HISTORIES: 'set_incident_histories',
}

export const actions = {
  FETCH_ONGOING_INCIDENTS: 'fetch_ongoing_incidents',
  FETCH_RESOLVED_INCIDENTS: 'fetch_resolved_incidents',
  FETCH_INCIDENT: 'fetch_incident',
  FETCH_INCIDENT_HISTORIES: 'fetch_incident_histories',
  SUBSCRIBE: 'subscribe',
  CONFIRM_SUBSCRIBE: 'confirm_subscribe',
  UNSUBSCRIBE: 'unsubscribe',
}

export default new Vuex.Store({
  plugins: [
    (store) => {
      store.subscribeAction({
        before: (action, state) => {
          store.commit(mutations.SET_LOADING)
        },
        after: (action, state) => {
          store.commit(mutations.UNSET_LOADING)
        },
      })
    },
  ],
  state: {
    loading: true,
    snackbar: {
      text: '',
      color: '',
      timeout: '',
      queueNo: 0, // added to allow for notification scheduling in future
    },
    ongoing: {
      status: null,
      incidents: null,
    },
    resolved: {
      incidents: null,
    },
    detail: {
      incident: null,
      incidentHistories: null,
    },
  },
  mutations: {
    [mutations.SET_LOADING](state) {
      state.loading = true
    },
    [mutations.SET_SNACKBAR](state, snackbar) {
      state.snackbar = {
        ...snackbar,
        queueNo: state.snackbar.queueNo + 1,
      }
    },
    [mutations.UNSET_LOADING](state) {
      state.loading = false
    },
    [mutations.SET_ONGOING_INCIDENTS](state, incidents) {
      state.ongoing.incidents = incidents
    },
    [mutations.SET_ONGOING_STATUS](state, status) {
      state.ongoing.status = status
    },
    [mutations.SET_RESOLVED_INCIDENTS](state, incidents) {
      state.resolved.incidents = incidents
    },
    [mutations.SET_INCIDENT](state, incident) {
      state.detail.incident = incident
    },
    [mutations.SET_INCIDENT_HISTORIES](state, incidentHistories) {
      state.detail.incidentHistories = incidentHistories
    },
  },
  actions: {
    async [actions.FETCH_ONGOING_INCIDENTS]({ commit }) {
      const response = await api.get(urls.ongoingIncidents)
      const payload = response.data
      commit(mutations.SET_ONGOING_INCIDENTS, payload.incidents)
      commit(mutations.SET_ONGOING_STATUS, payload.status)
    },
    async [actions.FETCH_RESOLVED_INCIDENTS]({ commit }, timeFilter) {
      const response = await api.get(urls.resolvedIncidents, {
        params: {
          time_period: timeFilter,
        },
      })
      const payload = response.data
      commit(mutations.SET_RESOLVED_INCIDENTS, payload)
    },
    async [actions.FETCH_INCIDENT]({ commit }, incidentId) {
      const response = await api.get(urls.incidentDetail(incidentId))
      const payload = response.data
      commit(mutations.SET_INCIDENT, payload)
    },
    async [actions.FETCH_INCIDENT_HISTORIES]({ commit }, incidentId) {
      const response = await api.get(urls.incidentHistories(incidentId))
      const payload = response.data
      commit(mutations.SET_INCIDENT_HISTORIES, payload)
    },
    async [actions.SUBSCRIBE]({ commit }, data) {
      await api.post(urls.subscribe, data)
    },
    async [actions.CONFIRM_SUBSCRIBE]({ commit }, data) {
      await api.post(urls.confirmSubscribe, data)
    },
    async [actions.UNSUBSCRIBE]({ commit }, params) {
      await api.get(urls.unsubscribe, { params })
    },
  },
  getters: {
    getServiceStatusEnum(state) {
      const status = state.ongoing.status
      return Vue.prototype.$enums.serviceStatus.getObj(status)
    },
  },
  modules: {},
})
