<template>
  <v-card
    color="transparent"
    elevation="0"
    :to="{
      name: $routerUrls.incidentDetail,
      params: {
        id: incident.id,
      },
    }"
  >
    <v-container>
      <v-row class="mb-n7 hidden-sm-and-down">
        <v-col cols="6">
          <span class="text-caption">
            {{ $utils.timestampToHumanFormat(incident.time_opened) }}
            -
            {{ $utils.timestampToHumanFormat(incident.time_resolved) }}
          </span>
        </v-col>
        <v-col cols="2">
          <span class="text-caption">
            <strong>Duration:</strong>
            {{ $utils.timestampToDurationHumanFormat(incident.time_opened) }}
          </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" md="1">
          <v-chip small label outlined color="green">Resolved </v-chip>
        </v-col>
        <v-col> {{ incident.title }} </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: 'IncidentCard',
  props: {
    incident: Object,
  },
}
</script>
