<template>
  <v-app-bar app color="blue" dark>
    <div class="d-flex align-center">
      <v-img
        alt="GOP Logo"
        class="shrink mr-2"
        contain
        src="@/assets/logo.png"
        transition="scale-transition"
        width="100"
      />
    </div>
    <v-spacer />

    <div class="d-flex justify-right">
      <subscribe-button />
    </div>
  </v-app-bar>
</template>

<script>
import SubscribeButton from '@/components/SubscribeButton'
export default {
  name: 'AppBar',
  components: {
    SubscribeButton,
  },
}
</script>
