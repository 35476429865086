<template></template>

<script>
import { actions } from '@/store'

export default {
  name: 'ConfirmSubscribeRedirect',
  created() {
    const params = {
      token: this.$route.query.token,
    }
    this.$store
      .dispatch(actions.CONFIRM_SUBSCRIBE, params)
      .then(() => {
        this.$router.push({ name: this.$routerUrls.confirmSubscribeSuccess })
      })
      .catch((error) => {
        console.log(error)
        this.$router.push({ name: this.$routerUrls.confirmSubscribeFail })
      })
  },
}
</script>
