<template>
  <div>
    <template v-if="!loading || histories">
      <incident-history-card
        v-for="history in histories"
        :history="history"
        :key="history.id"
      />
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import loadingMixin from '@/mixins/loadingMixin'
import IncidentHistoryCard from '@/components/IncidentHistoryCard'
import { actions } from '@/store'

export default {
  name: 'IncidentHistories',
  mixins: [loadingMixin],
  components: {
    IncidentHistoryCard,
  },
  computed: mapState({
    histories: (state) => state.detail.incidentHistories,
  }),
  mounted() {
    this.$store.dispatch(actions.FETCH_INCIDENT_HISTORIES, this.$route.params.id)
  },
}
</script>
