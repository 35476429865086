import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import enums from './plugins/enums'
import routerUrls from './plugins/routerUrls'
import ui from './plugins/ui'
import utils from './plugins/utils'
import setUpInterceptors from './api/interceptors'

Vue.config.productionTip = false

// interceptors
setUpInterceptors()

new Vue({
  router,
  store,
  vuetify,
  enums,
  routerUrls,
  ui,
  utils,
  render: (h) => h(App),
}).$mount('#app')
