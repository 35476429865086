import Vue from 'vue'
import Enums from '@/constants/enums'

Enums.install = function (Vue) {
  Vue.prototype.$enums = Enums
}

Vue.use(Enums)

export default Enums
