<template>
  <v-dialog v-model="dialog" width="400">
    <template v-slot:activator="{ on, attrs }">
      <v-btn dark v-bind="attrs" v-on="on"> Subscribe </v-btn>
    </template>

    <v-card>
      <v-card-title class="text-h5 grey lighten-2"> Subscribe</v-card-title>

      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-text-field
            v-model="email"
            :rules="emailRules"
            required
            placeholder="Enter your email address"
            @keydown.enter="subscribe"
          />
          <span class="text-caption"
            >Sign up for receiving instant updates regarding GOP. You will
            receive a confirmation email where you will be prompted to verify
            your email address.</span
          >
        </v-form>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="subscribe" :disabled="!valid">
          Okay
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { actions } from '@/store'
export default {
  data() {
    return {
      valid: true,
      dialog: false,
      email: null,
      emailRules: [(v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'],
    }
  },
  methods: {
    async subscribe() {
      this.dialog = false
      if (!this.valid) return
      try {
        await this.$store.dispatch(actions.SUBSCRIBE, { email: this.email })
        this.showSnackbar({ text: 'Subscribed successfully!', color: 'green' })
      } catch (error) {
        console.log(error)
        this.showSnackbar(error.snackbar)
      } finally {
        this.email = null
      }
    },
  },
}
</script>
