import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import IncidentDetail from '@/views/IncidentDetail.vue'
import UnsubscribeRedirect from '@/views/UnsubscribeRedirect.vue'
import UnsubscribeSuccess from '@/views/UnsubscribeSuccess.vue'
import UnsubscribeFail from '@/views/UnsubscribeFail.vue'
import ConfirmSubscribeRedirect from '@/views/ConfirmSubscribeRedirect.vue'
import ConfirmSubscribeSuccess from '@/views/ConfirmSubscribeSuccess.vue'
import ConfirmSubscribeFail from '@/views/ConfirmSubscribeFail.vue'

Vue.use(VueRouter)

export const routerUrlNames = {
  home: 'Home',
  incidentDetail: 'Incident Detail',
  confirmSubscribeRedirect: 'Confirm Subscribe Redirect',
  confirmSubscribeSuccess: 'Confirm Subscribe Success',
  confirmSubscribeFail: 'Confirm Subscribe Fail',
  unsubscribeRedirect: 'Unsubscribe Redirect',
  unsubscribeSuccess: 'Unsubscribe Success',
  unsubscribeFail: 'Unsubscribe Fail',
}

const routes = [
  {
    path: '/',
    name: routerUrlNames.home,
    component: Home,
  },
  {
    path: '/incident/:id',
    name: routerUrlNames.incidentDetail,
    component: IncidentDetail,
  },
  {
    path: '/subscribe/confirm/',
    name: routerUrlNames.confirmSubscribeRedirect,
    component: ConfirmSubscribeRedirect,
  },
  {
    path: '/subscribe/confirm/success',
    name: routerUrlNames.confirmSubscribeSuccess,
    component: ConfirmSubscribeSuccess,
  },
  {
    path: '/subscribe/confirm/fail',
    name: routerUrlNames.confirmSubscribeFail,
    component: ConfirmSubscribeFail,
  },
  {
    path: '/unsubscribe/:id/:email/',
    name: routerUrlNames.unsubscribeRedirect,
    component: UnsubscribeRedirect,
  },
  {
    path: '/unsubscribe/success',
    name: routerUrlNames.unsubscribeSuccess,
    component: UnsubscribeSuccess,
  },
  {
    path: '/unsubscribe/fail',
    name: routerUrlNames.unsubscribeFail,
    component: UnsubscribeFail,
  },
]

const router = new VueRouter({
  routes,
})

export default router
