<template>
  <v-chip label>
    <v-icon
      :color="$enums.serviceStatus.getObj(service.status).color"
      class="mr-2"
      >{{ $enums.serviceStatus.getObj(service.status).icon }}
    </v-icon>
    {{ service.name }}
  </v-chip>
</template>

<script>
export default {
  props: {
    service: {
      type: Object,
      required: true,
    },
  },
  created() {
    console.log(this.service)
  },
}
</script>
