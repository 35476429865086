<template></template>

<script>
import { actions } from '@/store'

export default {
  name: 'UnsubscribeRedirect',
  created() {
    const params = {
      subscriber_id: parseInt(this.$route.params.id),
      email: this.$route.params.email,
    }
    this.$store
      .dispatch(actions.UNSUBSCRIBE, params)
      .then(() => {
        this.$router.push({ name: this.$router.unsubscribeSuccess })
      })
      .catch((error) => {
        console.log(error)
        this.$router.push({ name: this.$router.unsubscribeFail })
      })
  },
}
</script>
