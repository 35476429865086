<template>
  <v-container v-if="!loading || status">
    <!-- Status Banner -->
    <v-row class="text-center" justify="center">
      <v-col xl="8">
        <v-banner :color="status.color" rounded dark>
          <span class="text-h6">
            <v-icon>{{ status.icon }}</v-icon>
            {{ status.text }}
          </span>
        </v-banner>
      </v-col>
    </v-row>

    <!-- Ongoing Incidents -->
    <v-row justify="center" v-if="incidents.length != 0">
      <v-col xl="8">
        <div class="text-h6 mb-2">Ongoing Incidents</div>
        <v-card
          v-for="incident in incidents"
          :key="incident.id"
          :style="cardStyle"
          :to="{
            name: $routerUrls.incidentDetail,
            params: { id: incident.id },
          }"
        >
          <v-container>
            <v-row class="mb-n7 hidden-sm-and-down">
              <v-col cols="3">
                <span class="text-caption">
                  <strong>Started:</strong>
                  {{ $utils.timestampToHumanFormat(incident.time_opened) }}
                </span>
              </v-col>
              <v-col>
                <span class="text-caption">
                  <strong>Last Updated:</strong>
                  {{
                    $utils.timestampToHumanFormat(incident.time_last_updated)
                  }}
                </span>
              </v-col>
              <v-spacer />
              <v-col cols="3" md="2">
                <span class="text-caption">
                  <strong>Duration:</strong>
                  {{
                    $utils.timestampToDurationHumanFormat(incident.time_opened)
                  }}
                </span>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <span class="text-h5">
                  <v-icon :color="status.color" class="mr-1">{{
                    status.icon
                  }}</v-icon>
                  {{ incident.title }}
                </span></v-col
              >
            </v-row>

            <v-card-text>
              <v-row v-if="incident.recent_update">
                <v-col class="ml-4"
                  >Recent update:
                  {{ incident.recent_update.description }}</v-col
                >
              </v-row>
              <v-row>
                <v-col class="ml-4">
                  <span class="text-subtitle-2">
                    Affected Services
                  </span></v-col
                >
              </v-row>
              <v-row class="mt-0">
                <v-col class="ml-4">
                  <v-service-chip
                    v-for="service in incident.services"
                    :key="service.id"
                    :service="service"
                    :class="'mr-2'"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import { actions } from '@/store'
import VServiceChip from '@/components/VServiceChip'
import loadingMixin from '@/mixins/loadingMixin'

export default {
  name: 'StatusSummary',
  components: {
    VServiceChip,
  },
  mixins: [loadingMixin],
  computed: {
    status() {
      return this.$store.getters.getServiceStatusEnum
    },
    cardStyle() {
      const color = this.status.color
      return {
        border: '1px solid ' + color,
        'border-left': '5px solid ' + color,
      }
    },
    ...mapState({
      incidents: (state) => state.ongoing.incidents,
    }),
  },
  mounted() {
    this.$store.dispatch(actions.FETCH_ONGOING_INCIDENTS)
  },
}
</script>
