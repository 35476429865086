import Vue from 'vue'
import { routerUrlNames as RouterUrls } from '@/router'

RouterUrls.install = function (Vue) {
  Vue.prototype.$routerUrls = RouterUrls
}

Vue.use(RouterUrls)

export default RouterUrls
