<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="auto">
        <v-row>
          <v-col>
            <span class="text-caption">
              {{ $utils.timestampToHumanFormat(history.time_created) }}</span
            >
          </v-col>
        </v-row>
        <v-row class="mt-n8">
          <v-col>
            <span class="text-h6">
              {{ $enums.incidentStatus.getObj(history.incident_status).text }}
            </span>
          </v-col>
        </v-row>
      </v-col>
      <v-col class="hidden-sm-and-down" cols="2" />
      <v-col cols="12" md="7">
        {{ history.description }}
      </v-col>
      <v-col cols="12" class="hidden-md-and-up">
        <v-divider />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'IncidentHistoryCard',
  props: {
    history: Object,
  },
}
</script>
