/**
 * Enum for overall service status.
 * @readonly
 * @enum {{text: string, value: number, color: string, icon: string}}
 */
export const ServiceStatusEnum = Object.freeze({
  OPERATIONAL: {
    text: 'Operational',
    value: 1,
    color: 'green',
    icon: 'mdi-check-circle',
  },
  DEGRADED_PEFORMANCE: {
    text: 'Degraded Performance',
    value: 2,
    color: 'orange',
    icon: 'mdi-minus-circle',
  },
  PARTIAL_OUTAGE: {
    text: 'Partial Outage',
    value: 3,
    color: 'red accent-1',
    icon: 'mdi-alert-circle',
  },
  MAJOR_OUTAGE: {
    text: 'Major Outage',
    value: 4,
    color: 'red accent-5',
    icon: 'mdi-close-circle',
  },
})

/**
 * Enum for incident status.
 * @readonly
 * @enum {{text: string, value: number, description: string}}
 */
export const IncidentStatusEnum = Object.freeze({
  INVESTIGATING: {
    text: 'Investigating',
    value: 1,
    description: 'Initial update to figure out error',
    color: 'red',
  },
  IDENTIFIED: {
    text: 'Identified',
    value: 2,
    description: 'Acknowledgement of issue',
    color: 'red accent-1',
  },
  FIXING: {
    text: 'Fixing',
    value: 3,
    description: 'In-progress fix',
    color: 'lime',
  },
  VERIFYING: {
    text: 'Verifying',
    value: 4,
    description: 'Confirm whether applied fix solves the problem',
    color: 'light-green',
  },
  CONCLUDE: {
    text: 'Conclude',
    value: 5,
    description: 'Resolves incident with comment',
    color: 'green',
  },
})

/**
 * Enum for time period of history.
 * @readonly
 * @enum {{text: string, value: number, description: string}}
 */
export const TimePeriodFilterEnum = Object.freeze({
  LAST_24_HOURS: {
    value: 1,
    text: 'Last 24 hours',
  },
  LAST_7_DAYS: {
    value: 2,
    text: 'Last 7 days',
  },
  LAST_30_DAYS: {
    value: 3,
    text: 'Last 30 days',
  },
})

export default {
  serviceStatus: {
    enum: ServiceStatusEnum,
    array: Object.values(ServiceStatusEnum),
    getObj: (val) => Object.values(ServiceStatusEnum)[val - 1],
  },
  incidentStatus: {
    enum: IncidentStatusEnum,
    array: Object.values(IncidentStatusEnum),
    getObj: (val) => Object.values(IncidentStatusEnum)[val - 1],
  },
  timePeriodFilter: {
    enum: TimePeriodFilterEnum,
    array: Object.values(TimePeriodFilterEnum),
    getObj: (val) => Object.values(TimePeriodFilterEnum)[val - 1],
  },
}
