export const BACKEND_URL = process.env.VUE_APP_BACKEND_URL

export const urls = {
  ongoingIncidents: 'incidents/ongoing/',
  resolvedIncidents: 'incidents/resolved/',
  incidentDetail: (id) => `incidents/${id}/`,
  incidentHistories: (id) => `incidents/${id}/histories/`,
  subscribe: 'subscribe/',
  confirmSubscribe: 'subscribe/confirm/',
  unsubscribe: `unsubscribe/`,
}

// sync with libs/constants in backend, except error_general
export const errorKeys = {
  error_general: {
    text: 'error_general',
    snackbar: {
      text: 'Something went wrong. Try again!',
      color: 'error',
    },
  },
  error_invalid_subscriber: {
    snackbar: {
      text: 'Something went wrong!',
      color: 'error',
    },
  },
  error_subscriber_already_exists: {
    snackbar: {
      text: 'This email has already been subscribed!',
      color: 'error',
    },
  },
}
