<template>
  <v-container>
    <v-row justify="center">
      <v-col xl="8">
        <v-container class="ma-0 pa-0">
          <v-row align="baseline">
            <v-col md="2">
              <span class="text-h6">Incident History</span>
            </v-col>
            <v-spacer />
            <v-col md="2" class="mb-0 pb-0">
              <v-select
                v-model="timePeriodFilter"
                solo
                flat
                dense
                :menu-props="{ bottom: true, offsetY: true }"
                :items="$enums.timePeriodFilter.array"
                item-text="text"
                item-value="value"
              ></v-select>
            </v-col>
          </v-row>
          <v-row class="mt-n8">
            <v-col>
              <v-divider />
            </v-col>
          </v-row>

          <template v-for="incident in incidents">
            <incident-card :incident="incident" :key="incident.id" />
            <v-divider :key="`key-${incident.id}`" />
          </template>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import IncidentCard from '@/components/IncidentCard.vue'
import loadingMixin from '@/mixins/loadingMixin'
import { actions } from '@/store'

export default {
  name: 'IncidentHistory',
  mixins: [loadingMixin],
  components: {
    IncidentCard,
  },
  data() {
    return {
      timePeriodFilter: this.$enums.timePeriodFilter.enum.LAST_24_HOURS.value,
    }
  },
  computed: mapState({
    incidents: (state) => state.resolved.incidents,
  }),
  watch: {
    timePeriodFilter: function (timePeriodFilter) {
      this.$store.dispatch(actions.FETCH_RESOLVED_INCIDENTS, timePeriodFilter)
    },
  },
  mounted() {
    this.$store.dispatch(
      actions.FETCH_RESOLVED_INCIDENTS,
      this.timePeriodFilter
    )
  },
}
</script>
