<template>
  <v-container v-if="!loading || incident">
    <v-row justify="center">
      <v-col cols="12" md="8">
        <v-container>
          <v-row>
            <v-col class="ml-n4">
              <v-btn text small to="/"
                ><v-icon left>mdi-chevron-left</v-icon>Back to status
                page</v-btn
              >
            </v-col>
          </v-row>

          <v-row class="mt-8">
            <v-col cols="12" md="8">
              <v-row no-gutters>
                <v-col cols="12" md="auto">
                  <v-chip
                    class="mr-2"
                    label
                    outlined
                    color="red accent-2"
                    v-if="!incident.time_resolved"
                  >
                    Ongoing
                  </v-chip>
                </v-col>
                <v-col class="mt-1">
                  <span class="text-subtitle-2">
                    <strong>Started:</strong>
                    {{ $utils.timestampToHumanFormat(incident.time_opened) }}
                  </span>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <span class="text-h6"> {{ incident.title }}</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <span v-html="incident.description" />
                </v-col>
              </v-row>
            </v-col>
            <v-spacer />
            <v-col cols="12" md="3">
              <v-row class="mb-n6">
                <v-col>
                  <span class="text-caption"> Duration </span>
                </v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col>
                  <span class="text-h7">
                    <strong>{{
                      $utils.timestampToDurationHumanFormat(
                        incident.time_opened
                      )
                    }}</strong>
                  </span>
                </v-col>
              </v-row>
              <v-row class="mt-2 mb-n6">
                <v-col>
                  <span class="text-caption"> Affected Services </span>
                </v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col>
                  <v-service-chip
                    v-for="service in incident.services"
                    :key="service.id"
                    :service="service"
                    :class="`mt-2`"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="10"> <v-divider /> <incident-histories /> </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import loadingMixin from '@/mixins/loadingMixin'
import VServiceChip from '@/components/VServiceChip'
import IncidentHistories from '@/components/IncidentHistories'
import { actions } from '@/store'

export default {
  name: 'IncidentDetail',
  mixins: [loadingMixin],
  components: {
    IncidentHistories,
    VServiceChip,
  },
  computed: mapState({
    incident: (state) => state.detail.incident,
  }),
  mounted() {
    this.$store.dispatch(actions.FETCH_INCIDENT, this.$route.params.id)
  },
}
</script>
