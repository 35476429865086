import api from '.'
import { errorKeys } from '@/constants/api'

export default () => {
  api.interceptors.response.use(
    function (response) {
      const payload = response.data
      if (payload.errors) {
        const error = payload.errors.error
        const errorObj = errorKeys[error] || errorKeys.error_general
        return Promise.reject(errorObj)
      }
      return payload
    },
    function (error) {
      console.log(error)
      return Promise.reject(errorKeys.error_general)
    }
  )
}
