<template>
  <v-container>
    <status-summary />
    <incident-history />
  </v-container>
</template>

<script>
import StatusSummary from '@/components/StatusSummary.vue'
import IncidentHistory from '@/components/IncidentHistory.vue'
import loadingMixin from '@/mixins/loadingMixin'

export default {
  name: 'Home',
  mixins: [loadingMixin],

  components: {
    StatusSummary,
    IncidentHistory,
  },
}
</script>
